<template>
  <div>
    <van-nav-bar :title="$t('history')"
                 left-arrow
                 :border="false"
                 fixed
                 @click-right="openEdit"
                 :placeholder="true"
                 @click-left="onClickLeft"
                 z-index="999">
      <template #right>
        <van-icon :name="rightIcon" size="18"/>
      </template>
    </van-nav-bar>
    <div class="history-content">
      <van-empty :image="require('@/assets/empty-image-default.png')" v-show="historyList.length <= 0"/>
      <van-grid :column-num="3" :gutter="5" :border="false">
          <van-grid-item v-show="historyList.length > 0" v-for="historyInfo in historyList" :key="historyInfo.vod_id"
                         @click="handClick(historyInfo.vod_id, historyInfo.audio_type, historyInfo.collection)"
          >
            <slot name="default">
              <div class="van-grid-item-custom">
                <div style="position:relative">
                  <van-image :src="historyInfo.cover_url" class="image_item" />
                  <audioLangTag v-show="$app_country === 'hi'" :content="historyInfo.audio_language"/>
                </div>
                <div class="van-grid-item-text">
                  {{ historyInfo.vod_name }}
                </div>
                <div class="van-grid-item-shade" v-if="isEdit===1 && delHistoryList.includes(historyInfo.vod_id)">
                  <van-icon name="passed" size="20" color="var(--title-color)"/>
                </div>
              </div>
            </slot>
          </van-grid-item>
      </van-grid>
    </div>
    <van-tabbar v-if="isEdit === 1" safe-area-inset-bottom  z-index="999" :placeholder="true">
      <van-tabbar-item @click="selectAll"><div class="history-tabbar-button">{{selectTxt}}</div></van-tabbar-item>
      <van-tabbar-item @click="delHistory"><div class="history-tabbar-button" style="color: red">{{$t('delete')}}({{delHistoryList.length}})</div></van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import audioLangTag from "@/components/audioLangTag";

export default {
  name: "index",
  components: {
    audioLangTag
  },
  data() {
    return {
      historyList: [],
      rightIcon: "edit",
      isEdit: 0,
      delHistoryList: [],
      selectTxt: this.$t('selectedAll')
    }
  },
  mounted() {
    this.getHistory()
  },
  methods: {
    getHistory() {
      let historyStr = localStorage.getItem('user_history')
      if (historyStr) {
        this.historyList = JSON.parse(historyStr)
      }
    },
    onClickLeft() {
      this.$router.go(-1)
    },
    toPlays(vod_id, audio_type, collection) {
      const query = {
        vod_id,
        audio_type,
        collection
      }
      this.$router.push({
        path: '/play',
        query
      })
    },
    openEdit(){
      if (this.isEdit === 0) {
        this.isEdit = 1
        this.rightIcon = 'cross'
      } else {
        this.isEdit = 0
        this.rightIcon = 'edit'
        this.delHistoryList = []
      }
    },
    selDelList(vod_id) {
      if (this.delHistoryList.includes(vod_id)) {
        this.delHistoryList = this.delHistoryList.filter(item => item !== vod_id);
        this.selectTxt = this.$t('selectedAll')
      } else {
        this.delHistoryList.push(vod_id)
        if (this.delHistoryList.length == this.historyList.length) {
          this.selectTxt = this.$t('deselect')
        }
      }
    },
    handClick(vod_id, audio_type, collection) {
      if (this.isEdit === 1) {
        this.selDelList(vod_id)
      } else {
        this.toPlays(vod_id, audio_type, collection)
      }
    },
    selectAll(){
      if (this.delHistoryList.length == this.historyList.length) {
        this.delHistoryList = []
        this.selectTxt = this.$t('selectedAll')
      } else {
        this.delHistoryList = []
        for (let i = 0; i < this.historyList.length; i++) {
          this.delHistoryList.push(this.historyList[i].vod_id)
        }
        this.selectTxt = this.$t('deselect')
      }
    },
    delHistory() {
      for (let i = 0; i < this.delHistoryList.length; i++) {
        let vod_id = this.delHistoryList[i]

        this.historyList.forEach((item, index) => {
          if (item.vod_id === vod_id) {
            this.historyList.splice(index, 1);
          }
        });
      }
      this.delHistoryList = []
      if (this.historyList.length > 0) {
        localStorage.setItem('user_history', JSON.stringify(this.historyList))
      } else {
        localStorage.removeItem('user_history')
      }
    }
  }
}
</script>

<style scoped>
/deep/ .van-grid-item__content--center {
  padding: 0;
  background-color: var(--main-color);
}

/deep/ .van-grid-item {
  min-width: 0;
  color: var(--minor-text-color);
}

/deep/ .van-nav-bar {
  border-bottom: 1px solid var(--minor-color);
}

/deep/ .van-nav-bar__title {
  font-weight: bold;
}

.van-grid-item-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  font-size: 14px;
  line-height: 30px;
  text-align: center;
}
.van-grid-item-custom{
  width: 100%;
  height: 100%;
  position: relative;
}
.van-grid-item-shade{
  position: absolute;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  z-index: 1;
  border-radius: 3px;
  display: flex;
  justify-content: right;
}

.history-content {
  padding-top: 10px;
}
/deep/.van-tabbar {
  border-top: 1px solid var(--tab-bar-top-border-color);
}
.image_item{
  width: 116px;
  display: block;
  border-radius: 3px;
  overflow: hidden;
  height: 164px;
}
.history-tabbar-button {
  line-height: 50px;
}
</style>
