<template>
  <div class="tag">{{ content }}</div>
</template>

<script>
export default {
  name: "audioLangTag",
  props: {
    content: String
  }
}
</script>

<style scoped>
.tag {
  position: absolute;
  top: 5px;
  left: 0;
  background-color: #37A0FC;
  color: white;
  line-height: 18px;
  font-size: 12px;
  padding: 0 5px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
</style>
